.flag-sprite {
  display: inline-block;
  width: 16px;
  height: 11px;
  background-image: url('assets/images/countries/flags.png');
}
.flag-a {
  background-position-x: 0;
}
.flag-_a {
  background-position-y: 0;
}
.flag-b {
  background-position-x: -16px;
}
.flag-_b {
  background-position-y: -11px;
}
.flag-c {
  background-position-x: -32px;
}
.flag-_c {
  background-position-y: -22px;
}
.flag-d {
  background-position-x: -48px;
}
.flag-_d {
  background-position-y: -33px;
}
.flag-e {
  background-position-x: -64px;
}
.flag-_e {
  background-position-y: -44px;
}
.flag-f {
  background-position-x: -80px;
}
.flag-_f {
  background-position-y: -55px;
}
.flag-g {
  background-position-x: -96px;
}
.flag-_g {
  background-position-y: -66px;
}
.flag-h {
  background-position-x: -112px;
}
.flag-_h {
  background-position-y: -77px;
}
.flag-i {
  background-position-x: -128px;
}
.flag-_i {
  background-position-y: -88px;
}
.flag-j {
  background-position-x: -144px;
}
.flag-_j {
  background-position-y: -99px;
}
.flag-k {
  background-position-x: -160px;
}
.flag-_k {
  background-position-y: -110px;
}
.flag-l {
  background-position-x: -176px;
}
.flag-_l {
  background-position-y: -121px;
}
.flag-m {
  background-position-x: -192px;
}
.flag-_m {
  background-position-y: -132px;
}
.flag-n {
  background-position-x: -208px;
}
.flag-_n {
  background-position-y: -143px;
}
.flag-o {
  background-position-x: -224px;
}
.flag-_o {
  background-position-y: -154px;
}
.flag-p {
  background-position-x: -240px;
}
.flag-_p {
  background-position-y: -165px;
}
.flag-q {
  background-position-x: -256px;
}
.flag-_q {
  background-position-y: -176px;
}
.flag-r {
  background-position-x: -272px;
}
.flag-_r {
  background-position-y: -187px;
}
.flag-s {
  background-position-x: -288px;
}
.flag-_s {
  background-position-y: -198px;
}
.flag-t {
  background-position-x: -304px;
}
.flag-_t {
  background-position-y: -209px;
}
.flag-u {
  background-position-x: -320px;
}
.flag-_u {
  background-position-y: -220px;
}
.flag-v {
  background-position-x: -336px;
}
.flag-_v {
  background-position-y: -231px;
}
.flag-w {
  background-position-x: -352px;
}
.flag-_w {
  background-position-y: -242px;
}
.flag-x {
  background-position-x: -368px;
}
.flag-_x {
  background-position-y: -253px;
}
.flag-y {
  background-position-x: -384px;
}
.flag-_y {
  background-position-y: -264px;
}
.flag-z {
  background-position-x: -400px;
}
.flag-_z {
  background-position-y: -275px;
}
.flag2x {
  background-size: 832px 572px;
}
.flag2x.flag-sprite {
  width: 32px;
  height: 22px;
}
.flag2x.flag-a {
  background-position-x: 0;
}
.flag2x.flag-_a {
  background-position-y: 0;
}
.flag2x.flag-b {
  background-position-x: -32px;
}
.flag2x.flag-_b {
  background-position-y: -22px;
}
.flag2x.flag-c {
  background-position-x: -64px;
}
.flag2x.flag-_c {
  background-position-y: -44px;
}
.flag2x.flag-d {
  background-position-x: -96px;
}
.flag2x.flag-_d {
  background-position-y: -66px;
}
.flag2x.flag-e {
  background-position-x: -128px;
}
.flag2x.flag-_e {
  background-position-y: -88px;
}
.flag2x.flag-f {
  background-position-x: -160px;
}
.flag2x.flag-_f {
  background-position-y: -110px;
}
.flag2x.flag-g {
  background-position-x: -192px;
}
.flag2x.flag-_g {
  background-position-y: -132px;
}
.flag2x.flag-h {
  background-position-x: -224px;
}
.flag2x.flag-_h {
  background-position-y: -154px;
}
.flag2x.flag-i {
  background-position-x: -256px;
}
.flag2x.flag-_i {
  background-position-y: -176px;
}
.flag2x.flag-j {
  background-position-x: -288px;
}
.flag2x.flag-_j {
  background-position-y: -198px;
}
.flag2x.flag-k {
  background-position-x: -320px;
}
.flag2x.flag-_k {
  background-position-y: -220px;
}
.flag2x.flag-l {
  background-position-x: -352px;
}
.flag2x.flag-_l {
  background-position-y: -242px;
}
.flag2x.flag-m {
  background-position-x: -384px;
}
.flag2x.flag-_m {
  background-position-y: -264px;
}
.flag2x.flag-n {
  background-position-x: -416px;
}
.flag2x.flag-_n {
  background-position-y: -286px;
}
.flag2x.flag-o {
  background-position-x: -448px;
}
.flag2x.flag-_o {
  background-position-y: -308px;
}
.flag2x.flag-p {
  background-position-x: -480px;
}
.flag2x.flag-_p {
  background-position-y: -330px;
}
.flag2x.flag-q {
  background-position-x: -512px;
}
.flag2x.flag-_q {
  background-position-y: -352px;
}
.flag2x.flag-r {
  background-position-x: -544px;
}
.flag2x.flag-_r {
  background-position-y: -374px;
}
.flag2x.flag-s {
  background-position-x: -576px;
}
.flag2x.flag-_s {
  background-position-y: -396px;
}
.flag2x.flag-t {
  background-position-x: -608px;
}
.flag2x.flag-_t {
  background-position-y: -418px;
}
.flag2x.flag-u {
  background-position-x: -640px;
}
.flag2x.flag-_u {
  background-position-y: -440px;
}
.flag2x.flag-v {
  background-position-x: -672px;
}
.flag2x.flag-_v {
  background-position-y: -462px;
}
.flag2x.flag-w {
  background-position-x: -704px;
}
.flag2x.flag-_w {
  background-position-y: -484px;
}
.flag2x.flag-x {
  background-position-x: -736px;
}
.flag2x.flag-_x {
  background-position-y: -506px;
}
.flag2x.flag-y {
  background-position-x: -768px;
}
.flag2x.flag-_y {
  background-position-y: -528px;
}
.flag2x.flag-z {
  background-position-x: -800px;
}
.flag2x.flag-_z {
  background-position-y: -550px;
}
.flag3x {
  background-size: 1248px 858px;
}
.flag3x.flag-sprite {
  width: 48px;
  height: 33px;
}
.flag3x.flag-a {
  background-position-x: 0;
}
.flag3x.flag-_a {
  background-position-y: 0;
}
.flag3x.flag-b {
  background-position-x: -48px;
}
.flag3x.flag-_b {
  background-position-y: -33px;
}
.flag3x.flag-c {
  background-position-x: -96px;
}
.flag3x.flag-_c {
  background-position-y: -66px;
}
.flag3x.flag-d {
  background-position-x: -144px;
}
.flag3x.flag-_d {
  background-position-y: -99px;
}
.flag3x.flag-e {
  background-position-x: -192px;
}
.flag3x.flag-_e {
  background-position-y: -132px;
}
.flag3x.flag-f {
  background-position-x: -240px;
}
.flag3x.flag-_f {
  background-position-y: -165px;
}
.flag3x.flag-g {
  background-position-x: -288px;
}
.flag3x.flag-_g {
  background-position-y: -198px;
}
.flag3x.flag-h {
  background-position-x: -336px;
}
.flag3x.flag-_h {
  background-position-y: -231px;
}
.flag3x.flag-i {
  background-position-x: -384px;
}
.flag3x.flag-_i {
  background-position-y: -264px;
}
.flag3x.flag-j {
  background-position-x: -432px;
}
.flag3x.flag-_j {
  background-position-y: -297px;
}
.flag3x.flag-k {
  background-position-x: -480px;
}
.flag3x.flag-_k {
  background-position-y: -330px;
}
.flag3x.flag-l {
  background-position-x: -528px;
}
.flag3x.flag-_l {
  background-position-y: -363px;
}
.flag3x.flag-m {
  background-position-x: -576px;
}
.flag3x.flag-_m {
  background-position-y: -396px;
}
.flag3x.flag-n {
  background-position-x: -624px;
}
.flag3x.flag-_n {
  background-position-y: -429px;
}
.flag3x.flag-o {
  background-position-x: -672px;
}
.flag3x.flag-_o {
  background-position-y: -462px;
}
.flag3x.flag-p {
  background-position-x: -720px;
}
.flag3x.flag-_p {
  background-position-y: -495px;
}
.flag3x.flag-q {
  background-position-x: -768px;
}
.flag3x.flag-_q {
  background-position-y: -528px;
}
.flag3x.flag-r {
  background-position-x: -816px;
}
.flag3x.flag-_r {
  background-position-y: -561px;
}
.flag3x.flag-s {
  background-position-x: -864px;
}
.flag3x.flag-_s {
  background-position-y: -594px;
}
.flag3x.flag-t {
  background-position-x: -912px;
}
.flag3x.flag-_t {
  background-position-y: -627px;
}
.flag3x.flag-u {
  background-position-x: -960px;
}
.flag3x.flag-_u {
  background-position-y: -660px;
}
.flag3x.flag-v {
  background-position-x: -1008px;
}
.flag3x.flag-_v {
  background-position-y: -693px;
}
.flag3x.flag-w {
  background-position-x: -1056px;
}
.flag3x.flag-_w {
  background-position-y: -726px;
}
.flag3x.flag-x {
  background-position-x: -1104px;
}
.flag3x.flag-_x {
  background-position-y: -759px;
}
.flag3x.flag-y {
  background-position-x: -1152px;
}
.flag3x.flag-_y {
  background-position-y: -792px;
}
.flag3x.flag-z {
  background-position-x: -1200px;
}
.flag3x.flag-_z {
  background-position-y: -825px;
}
.flag4x {
  background-size: 1664px 1144px;
}
.flag4x.flag-sprite {
  width: 64px;
  height: 44px;
}
.flag4x.flag-a {
  background-position-x: 0;
}
.flag4x.flag-_a {
  background-position-y: 0;
}
.flag4x.flag-b {
  background-position-x: -64px;
}
.flag4x.flag-_b {
  background-position-y: -44px;
}
.flag4x.flag-c {
  background-position-x: -128px;
}
.flag4x.flag-_c {
  background-position-y: -88px;
}
.flag4x.flag-d {
  background-position-x: -192px;
}
.flag4x.flag-_d {
  background-position-y: -132px;
}
.flag4x.flag-e {
  background-position-x: -256px;
}
.flag4x.flag-_e {
  background-position-y: -176px;
}
.flag4x.flag-f {
  background-position-x: -320px;
}
.flag4x.flag-_f {
  background-position-y: -220px;
}
.flag4x.flag-g {
  background-position-x: -384px;
}
.flag4x.flag-_g {
  background-position-y: -264px;
}
.flag4x.flag-h {
  background-position-x: -448px;
}
.flag4x.flag-_h {
  background-position-y: -308px;
}
.flag4x.flag-i {
  background-position-x: -512px;
}
.flag4x.flag-_i {
  background-position-y: -352px;
}
.flag4x.flag-j {
  background-position-x: -576px;
}
.flag4x.flag-_j {
  background-position-y: -396px;
}
.flag4x.flag-k {
  background-position-x: -640px;
}
.flag4x.flag-_k {
  background-position-y: -440px;
}
.flag4x.flag-l {
  background-position-x: -704px;
}
.flag4x.flag-_l {
  background-position-y: -484px;
}
.flag4x.flag-m {
  background-position-x: -768px;
}
.flag4x.flag-_m {
  background-position-y: -528px;
}
.flag4x.flag-n {
  background-position-x: -832px;
}
.flag4x.flag-_n {
  background-position-y: -572px;
}
.flag4x.flag-o {
  background-position-x: -896px;
}
.flag4x.flag-_o {
  background-position-y: -616px;
}
.flag4x.flag-p {
  background-position-x: -960px;
}
.flag4x.flag-_p {
  background-position-y: -660px;
}
.flag4x.flag-q {
  background-position-x: -1024px;
}
.flag4x.flag-_q {
  background-position-y: -704px;
}
.flag4x.flag-r {
  background-position-x: -1088px;
}
.flag4x.flag-_r {
  background-position-y: -748px;
}
.flag4x.flag-s {
  background-position-x: -1152px;
}
.flag4x.flag-_s {
  background-position-y: -792px;
}
.flag4x.flag-t {
  background-position-x: -1216px;
}
.flag4x.flag-_t {
  background-position-y: -836px;
}
.flag4x.flag-u {
  background-position-x: -1280px;
}
.flag4x.flag-_u {
  background-position-y: -880px;
}
.flag4x.flag-v {
  background-position-x: -1344px;
}
.flag4x.flag-_v {
  background-position-y: -924px;
}
.flag4x.flag-w {
  background-position-x: -1408px;
}
.flag4x.flag-_w {
  background-position-y: -968px;
}
.flag4x.flag-x {
  background-position-x: -1472px;
}
.flag4x.flag-_x {
  background-position-y: -1012px;
}
.flag4x.flag-y {
  background-position-x: -1536px;
}
.flag4x.flag-_y {
  background-position-y: -1056px;
}
.flag4x.flag-z {
  background-position-x: -1600px;
}
.flag4x.flag-_z {
  background-position-y: -1100px;
}
