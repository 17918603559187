.drag {
  touch-action: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  user-select: none;
  cursor: grab;
}

.img {
  width: 57px;
  height: 57px;
  border-radius: 50%;
}
